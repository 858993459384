@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Hebrew:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import 'tailwindcss/base';

body,
pre {
  margin: 0;
  font-family: 'Noto Sans Hebrew', sans-serif;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

body[dir='rtl'] {
  text-align: right;
  direction: rtl;
}

body[dir='ltr'] {
  text-align: left;
  direction: ltr;
}

html {
  background-color: #fafafa;
}
* {
  font-family: 'Noto Sans Hebrew', sans-serif;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.chakra-select__icon-wrapper[dir='rtl'] {
  left: 0.5rem !important;
  right: unset !important;
}

.companiesParent > * {
  width: 100% !important;
}

.calander {
  width: 100%;
  --primary-color: #3fb2d2;
}
.calander button {
  border: none;
  background-color: transparent;
}
.calander .month-change {
  display: grid;
  grid-template-columns: 1fr 1fr 3fr 1fr 1fr;
}
.calander .month-change .selected-month {
  cursor: default;
  font-weight: var(--bold);
  text-align: center;
}
.calander .week-days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-transform: uppercase;
  font-weight: var(--bold);
  font-size: 12px;
}
.calander .week-days .week-day {
  text-align: center;
  padding: 6px;
}
.calander .calender-days .month-days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}
.calander .calender-days .month-days .prev-month-day,
.calander .calender-days .month-days .next-month-day,
.calander .calender-days .month-days .this-month-day {
  text-align: center;
  color: var(--text-primary-color);
  position: relative;
  aspect-ratio: 1/1;
  border-radius: 50%;
}
.calander .calender-days .month-days .prev-month-day.today::before,
.calander .calender-days .month-days .next-month-day.today::before,
.calander .calender-days .month-days .this-month-day.today::before {
  content: '';
  position: absolute;
  width: 70%;
  height: 2px;
  left: 15%;
  bottom: 3px;
  background-color: var(--primary-color);
}
.calander .calender-days .month-days .prev-month-day:hover,
.calander .calender-days .month-days .next-month-day:hover,
.calander .calender-days .month-days .this-month-day:hover,
.calander .calender-days .month-days .prev-month-day.hovered,
.calander .calender-days .month-days .next-month-day.hovered,
.calander .calender-days .month-days .this-month-day.hovered {
  background-color: var(--primary-color-opacity);
}
.calander .calender-days .month-days .prev-month-day.selected,
.calander .calender-days .month-days .next-month-day.selected,
.calander .calender-days .month-days .this-month-day.selected {
  background-color: var(--primary-color);
  color: white;
}
.calander .calender-days .month-days .prev-month-day.selected.today::before,
.calander .calender-days .month-days .next-month-day.selected.today::before,
.calander .calender-days .month-days .this-month-day.selected.today::before {
  content: '';
  position: absolute;
  width: 70%;
  height: 2px;
  left: 15%;
  bottom: 3px;
  background-color: var(--text-secondary-color);
}
.calander .calender-days .month-days .prev-month-day,
.calander .calender-days .month-days .next-month-day {
  color: var(--text-thirdy-color);
}
.App.rtl .calander .month-change svg {
  transform: scale(-1, 1);
}

.sidebar-items {
  padding-inline-start: 25px;
}

.sidebar-items .sidebar-item:not(:last-child)::before {
  content: '';
  position: absolute;
  width: 80%;
  bottom: 0;
  border-bottom: 1px dashed #3eafcf;
}

.sidebar-items .sidebar-item {
  line-height: 87px;
  position: relative;
  padding-inline-start: 10%;
  color: #20273d;
  font-size: 20px;
}

.sidebar-items .sidebar-item.active {
  background-color: #f5f8fa;
  border-radius: 0px 10px 10px 0px;
}

.sidebar-items .sidebar-item.active::after {
  content: '';
  position: absolute;
  height: 40px;
  width: 5px;
  border-radius: 10px;
  background: #3fb2d2;
  top: 50%;
  transform: translateY(-50%);
  right: 5%;
}

.swal2-popup::before {
  content: '✖';
  display: block;
  position: absolute;
  top: -21px;
  left: 0;
  width: 32px;
  height: 32px;
  transform: translateY(-100%);
  color: white;
  border-radius: 50%;
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.swal2-popup::after {
  content: '';
  display: block;
  position: absolute;
  width: 100px;
  height: 100px;
  background-image: url(./assets/Info.png);
  background-repeat: no-repeat;
  background-position: center;
  top: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 51px;
  height: 51px;
  border-radius: 50%;
  background-color: #fff;
}

.swal2-popup {
  box-shadow: 0px 0px 25px rgba(62, 62, 62, 0.08) !important;
  border-radius: 20px !important;
}

.swal2-title {
  font-size: 26px !important;
  color: #475154 !important;
  background: #f5f8fa !important;
  border-radius: 10px !important;
  margin-inline: 30px !important;
  padding-block: 14px !important;
  margin-top: 52px !important;
}

.swal2-actions {
  display: flex !important;
  width: 100% !important;
  justify-content: space-evenly !important;
  padding-inline: 30px !important;
}

.swal2-actions button {
  border: 1px solid #3fb2d2 !important;
  background-color: transparent !important;
  color: #3fb2d2 !important;
  min-width: 100px !important;
  border-radius: 100px !important;
  min-width: 180px !important;
  height: 44px !important;
}
.swal2-actions .swal2-styled.swal2-confirm {
  color: white !important;
  background-color: #3fb2d2 !important;
  border-radius: 100px !important;
}

.lds-default {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-default div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #cef;
  border-radius: 50%;
  animation: lds-default 1.2s linear infinite;
}
.lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}
.lds-default div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}
.lds-default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}
.lds-default div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}
.lds-default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}
.lds-default div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}
.lds-default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}
.lds-default div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}
.lds-default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}
.lds-default div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}
.lds-default div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}
.lds-default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}
@keyframes lds-default {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}

@media (max-width: 1300px) {
  .chakra-modal__content:has(.interval) {
    max-height: calc(100%) !important;
    overflow: auto !important;
    margin-top: 0 !important;
  }
}
div:has(> .chakra-menu__menu-list.history-choose-company) {
  width: 100%;
}
.chakra-menu__menu-list.history-choose-company {
  width: 100% !important;
}

.responsive-iframe {
  width: 100%;
  aspect-ratio: 16 / 9;
}

.switch input[type='checkbox'] {
  opacity: 0;
  position: absolute;
  transform: scale(0);
}
.switch input[type='checkbox']:disabled + label {
  opacity: 0.5;
}
.switch input[type='checkbox']:focus + label::before {
  box-shadow: 3px 4px 16px rgba(0, 0, 0, 0.14);
}
.switch input[type='checkbox'] + label {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
}
.switch input[type='checkbox'] + label::before,
.switch input[type='checkbox'] + label::after {
  content: '';
  border-radius: 1.5em;
  transition: 150ms ease-in-out;
}
.switch input[type='checkbox'] + label::before {
  width: 4em;
  height: 2em;
  background-color: grey;
}
.switch input[type='checkbox'] + label::after {
  width: 1.8em;
  height: 1.8em;
  left: 0.2em;
  background-color: white;
  position: absolute;
}
.switch input[type='checkbox']:checked + label::before {
  background-color: #20273d;
}
.switch input[type='checkbox']:checked + label::after {
  transform: translateX(100%);
}

.rcw-launcher {
  text-align: center !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.rcw-sender {
  gap: 10px !important;
  justify-content: center !important;
  align-items: center !important;
}

.quick-button {
  background-color: #35cce6 !important;
  font-weight: normal !important;
  color: #fff !important;
}

select.chakra-select.en.css-en-66q2vj
  + .chakra-select__icon-wrapper.css-en-150m6y0 {
  right: 10px !important;
  left: auto !important;
}

body[dir='rtl'] .rcw-widget-container .rcw-client {
  margin-left: 0px !important;
  margin-right: auto !important;
}

body[dir='rtl'] .rcw-widget-container .rcw-launcher {
  align-self: flex-start !important;
}

body[dir='ltr'] .rcw-widget-container .rcw-launcher {
  align-self: flex-end !important;
}

.en + div {
  right: 7px !important;
  left: auto !important;
}

.tiblock {
  align-items: center;
  display: flex;
  height: 17px;
}

.ticontainer .tidot {
  background-color: #90949c;
}

.tidot {
  -webkit-animation: mercuryTypingAnimation 1.5s infinite ease-in-out;
  border-radius: 2px;
  display: inline-block;
  height: 4px;
  margin-right: 2px;
  width: 4px;
}

@keyframes mercuryTypingAnimation {
  0% {
    -webkit-transform: translateY(0px);
  }
  28% {
    -webkit-transform: translateY(-5px);
  }
  44% {
    -webkit-transform: translateY(0px);
  }
}

.tidot:nth-child(1) {
  -webkit-animation-delay: 200ms;
}
.tidot:nth-child(2) {
  -webkit-animation-delay: 300ms;
}
.tidot:nth-child(3) {
  -webkit-animation-delay: 400ms;
}

@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

[dir=rtl] .css-he-ghot30, .css-he-ghot30[dir=rtl] {
    --switch-thumb-x: calc(var(--switch-track-diff) * 1) !important;
}