.markdownBody {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 16px;
  line-height: 1.5;
  word-wrap: break-word;
  color: #24292e;
}

.markdownBody h1 {
  font-size: 2em;
  border-bottom: 1px solid #eaecef;
  padding-bottom: 0.3em;
  margin-top: 24px;
  margin-bottom: 16px;
  font-weight: 600;
  line-height: 1.25;
}

.markdownBody h2 {
  font-size: 1.5em;
  border-bottom: 1px solid #eaecef;
  padding-bottom: 0.3em;
  margin-top: 24px;
  margin-bottom: 16px;
  font-weight: 600;
  line-height: 1.25;
}

.markdownBody h3 {
  font-size: 1.25em;
  margin-top: 24px;
  margin-bottom: 16px;
  font-weight: 600;
  line-height: 1.25;
}

.markdownBody h4 {
  font-size: 1em;
  margin-top: 24px;
  margin-bottom: 16px;
  font-weight: 600;
  line-height: 1.25;
}

.markdownBody h5 {
  font-size: 0.875em;
  margin-top: 24px;
  margin-bottom: 16px;
  font-weight: 600;
  line-height: 1.25;
}

.markdownBody h6 {
  font-size: 0.85em;
  margin-top: 24px;
  margin-bottom: 16px;
  font-weight: 600;
  line-height: 1.25;
  color: #6a737d;
}

.markdownBody p {
  margin-top: 0;
  margin-bottom: 16px;
}

.markdownBody a {
  color: #0366d6;
  text-decoration: none;
}

.markdownBody a:hover {
  text-decoration: underline;
}

.markdownBody strong {
  font-weight: 600;
}

.markdownBody em {
  font-style: italic;
}

.markdownBody del {
  text-decoration: line-through;
}

.markdownBody ul,
.markdownBody ol {
  padding-left: 2em;
  margin-top: 0;
  margin-bottom: 16px;
}

.markdownBody li {
  margin-top: 0.25em;
}

.markdownBody blockquote {
  margin: 0;
  padding: 0 1em;
  color: #6a737d;
  border-left: 0.25em solid #dfe2e5;
}

.markdownBody pre {
  padding: 16px;
  overflow: auto;
  font-size: 85%;
  line-height: 1.45;
  background-color: #f6f8fa;
  border-radius: 3px;
}

.markdownBody code {
  padding: 0.2em 0.4em;
  margin: 0;
  font-size: 85%;
  background-color: rgba(27, 31, 35, 0.05);
  border-radius: 3px;
}

.markdownBody pre code {
  padding: 0;
  font-size: 100%;
  background-color: transparent;
}

.markdownBody img {
  max-width: 100%;
  box-sizing: content-box;
}

.markdownBody hr {
  height: 0.25em;
  padding: 0;
  margin: 24px 0;
  background-color: #e1e4e8;
  border: 0;
}

.markdownBody table {
  border-spacing: 0;
  border-collapse: collapse;
  margin-top: 0;
  margin-bottom: 16px;
}

.markdownBody table th,
.markdownBody table td {
  padding: 6px 13px;
  border: 1px solid #dfe2e5;
}

.markdownBody table tr {
  background-color: #fff;
  border-top: 1px solid #c6cbd1;
}

.markdownBody table tr:nth-child(2n) {
  background-color: #f6f8fa;
}

.markdownBody ul,
.markdownBody ol {
  padding-inline-start: 1.5em;
  margin-top: 0;
  margin-bottom: 16px;
}

.markdownBody ul {
  list-style-type: disc;
}

.markdownBody ul ul {
  list-style-type: circle;
}

.markdownBody ul ul ul {
  list-style-type: square;
}

.markdownBody ol {
  list-style-type: decimal;
}

.markdownBody ol ol {
  list-style-type: lower-alpha;
}

.markdownBody ol ol ol {
  list-style-type: lower-roman;
}

.markdownBody li {
  margin-top: 0.25em;
  /* margin-right: 1em; */
}

.markdownBody li > p {
  margin-top: 16px;
}

.markdownBody li + li {
  margin-top: 0.25em;
}
