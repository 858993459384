body {
  direction: rtl;
}

/* phone input */

.react-tel-input {
  direction: ltr;
  text-align: left;
}

.react-tel-input:has(.login-phone-input) .login-phone-input {
  width: 100%;
  margin-left: auto;
  margin: 0;
  padding-inline-start: 20px;
  height: 54px;
}

.react-tel-input:has(.login-phone-input) {
  display: flex;
  gap: 16px;
  flex-direction: row-reverse;
  padding-inline-start: 5px;
}
.react-tel-input:has(.login-phone-input) .flag-dropdown {
  position: static;
  width: 85px;
  background: #f9f9f9;
  box-shadow: 0px 0px 25px rgba(62, 62, 62, 0.18);
  border-radius: 4px;
  padding-inline: auto;
}
.react-tel-input:has(.login-phone-input) .flag-dropdown .selected-flag {
  padding-inline: 21px;
  font-size: 60px;
}

.react-tel-input:has(.login-phone-input)
  .flag-dropdown
  .selected-flag
  *:not(.arrow) {
  scale: 1.7;
  margin-left: 10px;
}

.react-tel-input:has(.login-phone-input) .flag-dropdown .selected-flag .arrow {
  left: -10px;
}

/* verify code */

.verify-code {
  --cell-width: 65px;
  --input-position: 0;
  --gap: 17px;
  display: flex;
  gap: var(--gap);
  position: relative;
  margin-bottom: 70px;
}

@media (max-width: 1300px) {
  .verify-code {
    --cell-width: 55px;
  }
}

@media (max-width: 400px) {
  .verify-code {
    --cell-width: 50px !important;
  }
}

.verify-code .display {
  aspect-ratio: 1;
  width: var(--cell-width);
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: center;
  border: 1.4184px solid #e2e2e2;
  border-radius: 11.3472px;
  position: relative;
}
.verify-code .display.shadows {
  box-shadow: 0px 4.53126px 33.9845px rgba(63, 178, 210, 0.34);
}
.verify-code input {
  position: absolute;
  width: var(--cell-width);
  transform: translateX(
    calc(var(--input-position) * (var(--cell-width) + var(--gap)))
  );
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 11.3472px;
  border: 1.41602px solid #3fb2d2;
  top: 0px;
  bottom: 0px;
  text-align: center;
  background-color: transparent;
  outline: none;
}
.verify-code input.hide {
  opacity: 0;
}
